<template
  ><div>
    <div class="page_padding nav_main">
      <div class="navigation">
        <router-link to="/" class="logo">
          <div class="imgWrap com_logo">
            <img src="../assets/source/images/logo.png" />
          </div>
        </router-link>
        <div v-show="is_mobile" class="menu_icon" @click="openMenu">
          <img src="../assets/source/images/menu_round.png" />
        </div>
        <div class="main_list" :class="{active: is_mobile_open}">
          <ul class="main">
            <li
              @mouseover="doMouseOver('info')"
              @mouseleave="doMouseLeave"
              :class="{active: is_active}"
            >
              <div>
                <router-link to="/info">
                  <span class="main_menu">
                    회사 소개
                  </span>
                </router-link>
              </div>
              <ul
                class="d_none"
                :class="[
                  {mobile: is_mobile},
                  {active: is_active && menu_kind == '회사 소개'},
                  {d_block: is_active && menu_kind == '회사 소개'},
                ]"
              >
                <li>
                  <router-link to="/info">
                    <span>스프링소프트</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/info/partnership">
                    <span>파트너십</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/info/map">
                    <span>오시는 길</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li
              @mouseover="doMouseOver('happytable')"
              @mouseleave="doMouseLeave"
              :class="{active: is_active}"
            >
              <div>
                <router-link to="/happytable">
                  <span class="main_menu">
                    해피테이블
                  </span>
                </router-link>
              </div>
              <ul
                class="d_none"
                :class="[
                  {mobile: is_mobile},
                  {active: is_active && menu_kind == '해피테이블'},
                  {d_block: is_active && menu_kind == '해피테이블'},
                ]"
              >
                <li>
                  <router-link to="/happytable">
                    <span>해피테이블이란?</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/happytable/reference">
                    <span>설치사례</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/game">
                <span class="main_menu">게임</span>
              </router-link>
            </li>
            <li>
              <router-link to="/cognitive">
                <span class="main_menu">인지평가</span>
              </router-link>
            </li>
            <li>
              <router-link to="/news">
                <span class="main_menu">소식</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import items from "../assets/source/json/menu.json";
export default {
  data() {
    return {
      pages: items,
      is_true: false,
      is_first: false,
      menu_kind: "",
      is_active: false,
      is_mobile: false,
      is_mobile_open: false,
    };
  },
  created() {
    this.check_mobile();
  },
  mounted() {
    window.addEventListener("resize", this.check_mobile);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.check_mobile);
  },
  watch: {
    $route() {
      if (this.is_mobile_open == true) {
        this.openMenu();
      }
    },
  },
  methods: {
    doMouseOver(kind) {
      if (window.innerWidth > 767) {
        if (kind == "info") {
          this.is_first = true;
          this.menu_kind = "회사 소개";
          this.is_active = true;
        } else {
          this.is_first = false;
          this.menu_kind = "해피테이블";
          this.is_active = true;
        }
        this.is_true = true;
        document.querySelector("body").style.overflow = "scroll";
      }
    },
    doMouseLeave: function() {
      if (window.innerWidth > 767) {
        this.is_true = false;
        this.is_active = false;
      }
    },
    check_mobile() {
      if (window.innerWidth < 768) {
        this.is_mobile = true;
      } else {
        this.is_mobile = false;
      }
    },
    openMenu() {
      if (this.is_mobile_open == false) {
        this.is_mobile_open = true;
        document.querySelector("body").style.overflow = "hidden";
      } else {
        this.is_mobile_open = false;
        document.querySelector("body").style.overflow = "scroll";
      }
    },
    mobileMenu() {
      if (window.innerWidth < 768) {
        this.is_active = true;
        this.is_true = true;
      }
    },
    check_menu_kind(menu_kind, main_menu) {
      if (window.innerWidth < 768) {
        this.is_active = true;
        this.is_true = true;
        return true;
      } else {
        if (menu_kind == main_menu) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  display: block;
}
a:visited {
  color: black;
}
.d_none {
  display: none;
}
.d_block {
  display: block;
}
.mobile.d_none {
  display: block;
}
ul.active {
  /* border: 1px solid #333333; */
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  /* border-radius: 20px; */
  position: absolute;
  margin: 0 1rem;
  padding: 0.5rem 0;
}
ul {
  background-color: #ffffff;
  text-align: center;
}
ul.main li {
  width: 8rem;
  line-height: 4rem;
  display: inline-block;
}
ul li span {
  color: #333;
}

ul.active.d_block li {
  width: unset;
  display: block;
  padding: 0 1.5rem;
  text-align: left;
  line-height: 2rem;
}
ul.main.active {
  color: #dc375d;
}
a.router-link-exact-active.router-link-active span {
  color: #dc375d;
  border-bottom: none;
}
li span:hover {
  font-family: "paybooc Bold";
  color: #dc375d;
}
li.router-link-exact-active {
  color: royalblue;
}
li .main_menu:hover {
  color: #dc375d !important;
  padding: 4px 0;
  border-bottom: 2px solid #dc375d !important;
}

.active {
  z-index: 100;
}
.router-link-active span.main_menu {
  /* 상위 경로일 때 하이라이트 처리 */
  color: #dc375d !important;
  padding: 4px 0;
  border-bottom: 2px solid #dc375d !important;
}
a.logo {
  display: flex;
  align-items: center;
}
a.imgWrap img {
  width: 100%;
}
.nav_main {
  position: fixed;
  width: 100%;
  background: white;
  z-index: 99;
  border-bottom: 1px solid #dddddd;
  height: 70px;
}
.navigation {
  position: relative;
}

.imgWrap img {
  width: 100%;
}
.imgWrap {
  position: absolute;
  top: 20%;
}

@media (min-width: 768px) and (max-width: 1024px) {
  ul.active {
    border: 1px solid #333333;
    border-radius: 20px;
    position: absolute;
    margin: 0;
    padding: 0.5rem 0;
  }
  ul.active li {
    width: unset !important;
    display: block;
    padding: 0 1.5rem;
    text-align: left;
    line-height: 2rem;
  }
  ul.main li {
    width: auto;
    padding: 0 1rem;
  }
}
@media (max-width: 767px) {
  a.logo {
    height: 64px;
  }
  .logo .com_logo {
    left: 20%;
  }
  .mobile_menu {
    height: 64px;
  }
  .menu_icon {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 10%;
  }
  .menu_icon img {
    width: 100%;
  }

  .imgWrap img {
    width: 200px;
  }
  ul.active {
    border: 0px;
    position: inherit;
    margin: unset;
    padding: 0.5rem 0;
  }
  ul.main li {
    width: 8rem;
    line-height: 2.6rem;
    display: block;
  }

  .main_list.active {
    display: block;
    position: absolute;
    width: 100%;
    top: 64px;
    left: -10%;
    padding-left: 10%;
    background: white;
    height: 100vh;
    text-align: left;
  }
  .main_list {
    display: none;
  }
  .nav_main {
    height: 70px;
  }
  .mobile.d_none span {
    font-size: 14px;
    color: gray;
  }
  .main li div {
    /* border-bottom: 1px solid gray; */
    width: 90%;
  }
  .main li {
    /* border-bottom: 1px solid gray; */
    /* width: 90% !important; */
    text-align: left;
  }
  .main li:last-child {
    border-bottom: 0px;
  }
}
@media (max-width: 300px) {
  .imgWrap img {
    width: 150px;
  }
}
</style>
