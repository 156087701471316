<template>
  <div id="app">
    <div id="nav">
      <div>
        <navi />
        <router-view
          id="MAIN_CON"
          class="main_contents"
          :style="{minHeight: footer_height}"
        />
        <foot></foot>
      </div>
    </div>
  </div>
</template>
<script>
import Navi from "./components/Navi.vue";
import Foot from "./views/Footer.vue";

export default {
  components: {Foot, Navi},
  data() {
    return {
      footer_height: "",
    };
  },
  methods: {
    getFooterSize() {
      var height;
      height = document.getElementById("FOOTER").clientHeight;
      height = height + "px";
      height = "calc(100vh - " + height + ")";
      this.footer_height = height;
    },
  },
  mounted() {
    this.getFooterSize();

    // CMS에서 홈페이지 링크 열 때
    if (window.name == "info") {
      this.$router.push("/info");
    } else if (window.name == "map") {
      this.$router.push("/info/map");
    }
  },

  beforeUpdate() {
    window.addEventListener("resize", this.getFooterSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.getFooterSize);
  },
};
</script>
<style>
* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2f2e2e;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
