<template>
  <div id="FOOTER" class="footer" ref="footer">
    <div class="page_padding height100">
      <div
        class="d-flex justify-content-between align-items-center footer_main height100"
      >
        <div class="logo">
          <image-component
            :is_jpg="false"
            name="footer_logo"
            path=""
          ></image-component>
        </div>
        <div class="company_info text-left textWhite width60 ">
          <div>
            <span class="fontMedium font15"
              >경기도 성남시 수정구 창업로 54 (시흥동 294-2) LH 기업성장센터
              807호</span
            >
          </div>
          <div>
            <span class="fontMedium font15"
              >suzf@springsoft.io | Tel : 070-8864-0486 | FAX :
              031-712-0486</span
            >
          </div>
          <div class="copyright">
            <span class="fontMedium font12"
              >Copyright ⓒSpring Soft CO.,LTD.ALL RIGHTS RESERVED.</span
            >
          </div>
        </div>
        <div class="d-flex sns_icon">
          <a
            class="marginLR"
            href="https://www.instagram.com/happytable_official/"
            target="_blank"
            ><div class="iconWrap flex-set">
              <img
                src="../assets/source/images/ic_instagram.png"
                alt="go to instagram"
              />
            </div>
          </a>
          <a
            href="https://www.facebook.com/happytable2020/?ref=bookmarks"
            target="_blank"
          >
            <div class="iconWrap flex-set">
              <img
                src="../assets/source/images/ic_facebook.png"
                alt="go to facebook"
              />
            </div>
          </a>
        </div>
        <div
          class="top_button"
          v-on:scroll="handleScroll"
          v-on:click="scrollTop"
          :class="{is_scroll: is_scrolled}"
        >
          <div class="iconWrap flex-set">
            <img src="../assets/source/images/ic_top.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageComponent from "../components/imageComponent.vue";
export default {
  components: {imageComponent},
  data() {
    return {
      is_scrolled: false,
      footer_height: 0,
    };
  },

  methods: {
    handleScroll() {
      if (window.scrollY == 0 || document.documentElement.scrollTop == 0) {
        this.is_scrolled = false;
      } else {
        this.is_scrolled = true;
      }
    },
    scrollTop() {
      window.scrollTo({top: 0, behavior: "smooth"});
    },
    getFooterSize() {
      var height;
      height = document.getElementById("FOOTER").clientHeight + "px";
      this.footer_height = height;
      height = "calc(100vh - " + this.footer_height + ")";

      //not working
      this.$emit("footer", height);
    },
  },

  mounted() {
    window.addEventListener("resize", this.getFootersize);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getFootersize);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.getFootersize);
  },
};
</script>

<style scoped>
.imgWrap {
  width: 200px;
  margin: auto 0;
}
.footer {
  /* height: 166px; */
  padding: 2rem 0;
  background-color: #333333;
}
.logo {
  margin-right: 2rem;
}
.height100 {
  height: 100%;
}
.textWhite {
  color: #a0a0a0;
}
span.font15 {
  line-height: 1.5rem;
}
.copyright {
  margin-top: 1rem;
}

.iconWrap {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  opacity: 0.8;
  background-color: #5f5d5d;
}
.iconWrap img {
  width: 50%;
  height: 50%;
}
.top_button .iconWrap {
  background-color: #dc375d;
  border-radius: 10px;
  opacity: 1;
}
.top_button.is_scroll {
  position: fixed;
  right: 60px;
  bottom: 60px;
}
@media (max-width: 767px) {
  .top_button.is_scroll {
    right: 30px;
    bottom: 30px;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .footer_main {
    flex-wrap: wrap;
  }
}
@media (max-width: 1023px) {
  .footer {
    /* height: 166px; */
    padding: 0.5rem 0;
  }
  .footer_main {
    flex-direction: column;
    justify-content: left !important;
    align-items: flex-start;
  }
  .company_info {
    width: 100%;
  }
  .sns_icon {
    margin: 0.5rem 0;
  }
  .sns_icon a:first-child {
    margin-left: 0 !important;
  }

  .logo {
    margin: 0.5rem 0;
  }
  .imgWrap {
    width: 150px;
  }
}
</style>
