import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    component: () => import("../views/Default.vue"),
    children: [
      {
        path: "",
        name: "main_non",
        component: () => import("../views/Main_non.vue"),
      },
      {
        path: "main",
        name: "main",
        component: () => import("../views/Main.vue"),
      },
    ],
  },
  {
    path: "/info",
    component: () => import("../views/Info/Info.vue"),
    children: [
      {
        path: "",
        name: "info",
        component: () => import("../views/Info/Springsoft.vue"),
      },
      // {
      //   path: "business",
      //   component: () => import("../views/Info/Business.vue"),
      // },
      {
        path: "partnership",
        name: "partnership",
        component: () => import("../views/Info/Partnership.vue"),
      },
      {
        path: "map",
        name: "map",
        component: () => import("../views/Info/Map.vue"),
      },
    ],
  },
  {
    path: "/happytable",
    component: () => import("../views/HappyTable/Happytable.vue"),
    children: [
      {
        path: "",
        component: () => import("../views/HappyTable/HappytableInfo.vue"),
      },
      {
        path: "reference",
        component: () => import("../views/HappyTable/Reference.vue"),
      },
      {
        path: "more",
        component: () => import("../views/HappyTable/ReferenceMore.vue"),
      },
    ],
  },
  {
    path: "/game",
    name: "game",
    component: () => import("../views/Game/Game.vue"),
    props: true,
  },
  {
    path: "/cognitive",
    name: "cognitive",
    component: () => import("../views/Cognition.vue"),
    props: true,
  },
  {
    path: "/news",
    component: () => import("../views/News/News.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0};
    }
  },
});

export default router;
