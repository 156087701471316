<template>
  <div class="imgWrap">
    <img :src="getImgSrc(path, name, is_jpg)" />
  </div>
</template>

<script>
export default {
  props: {
    is_jpg: Boolean,
    path: String,
    name: String,
  },
  methods: {
    getImgSrc(path, name, is_jpg) {
      var images;
      if (is_jpg == true) {
        // jpg 파일일 때
        if (path == "screenshots") {
          images = require.context(
            "../assets/source/images/screenshots/",
            false,
            /\.jpg$/
          );
        } else {
          images = require.context("../assets/source/images/", false, /\.jpg$/);
        }
        return images("./" + name + ".jpg");
      } else {
        // png 파일일 때
        if (path == "icons") {
          images = require.context(
            "../assets/source/images/icons/",
            false,
            /\.png$/
          );
        } else {
          images = require.context("../assets/source/images/", false, /\.png$/);
        }
        return images("./" + name + ".png");
      }
    },
  },
};
</script>

<style scoped>
.imgWrap img {
  width: 100%;
}
</style>
